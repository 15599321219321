
<template>
  <div>
    <Header />

    <!--資優數學營內容區-->
    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb-4">
            <ul role="tablist" class="nav nav-pills mb-3 pt-6 pb-4 lh-lg">
              <li class="nav-item">
                <a href="/#/Camp" role="tab" aria-controls="introduce" class="nav-link active">營隊介紹</a>
              </li>
              <li class="nav-item">
                <a href="/#/Camp/Step" role="tab" aria-controls="step" class="nav-link">營隊報名</a>
              </li>
              <li class="nav-item" v-if="isCampSearchOpen=='true'">
                <a href="/#/Camp/Search" role="tab" aria-controls="search" class="nav-link">營隊查詢</a>
              </li>
              <li class="nav-item">
                <a href="/#/Camp/Faq" role="tab" aria-controls="faq" class="nav-link">常見問題</a>
              </li>
            </ul>

            <div class="tab-content" v-html="newsDetail.content">
            </div>
          </div>
        </div>
      </div>
    </section>

    <!--資優數學營內容區結束-->
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  data() {
    return {
      newsDetail: {},
      isCampOpen: false,
      isCampApplyOpen: false,
      isCampSearchOpen: false,
      apiContent: (data) => this.userRequest.post("KC/Content", data),
    }
  },
  components: {
    Header,
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getCampIsOpen();
      await this.getCampApplyIsOpen();
      await this.getCampSearchIsOpen();
      if(this.isCampOpen=='false') {
        alert(new Date().getFullYear() + "年度尚未開放資優營");
        location.href = '/#/';
      }

      this.getDetail(4566);
    },
    getDetail(value) {
      this.newsDetail = {};
      let json = {
        TYPE: "HOME_DETAIL",
        content_SELECT: {
          contentId: value,
        },
      };
      this.FunctionToken(this.GetContentDetail, json);
    },
    GetContentDetail(data) {
      this.apiContent(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            let jsonData = JSON.parse(json.Data);
            this.newsDetail = {
              title: jsonData.outWeb.title,
              createDate: jsonData.outWeb.createDate,
              content: jsonData.outWeb.contents,
              modifyDate: jsonData.outWeb.modifyDate,
            };
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getCampIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_CAMP_ISOPEN",
        };

        let resDATA = await this.userRequest.post(
          "KC/CampYearData",
          dataJSON
        );
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCampApplyIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_APPLY_ISOPEN",
        };

        let resDATA = await this.userRequest.post(
          "KC/CampYearData",
          dataJSON
        );
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampApplyOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getCampSearchIsOpen() {
      try {
        let dataJSON = {
          TYPE: "FRONT_SEARCH_ISOPEN",
        };

        let resDATA = await this.userRequest.post(
          "KC/CampYearData",
          dataJSON
        );
        let jsonRdata = JSON.parse(resDATA.data);
        if (jsonRdata.Status) {
          this.isCampSearchOpen = jsonRdata.Data;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>