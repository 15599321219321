
<template>
  <div class="main-wrapper blog-single-left-sidebar">
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">報名時間已截止</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">首頁</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                我要報名
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="row justify-content-center align-items-center col-12">
          <div class="col-sm-6 col-xs-12">
            <div class="text-center">
              <img src="/assets/img/examroom/pic1.jpg" class="w-100 rounded" alt="" />
            </div>
          </div>
          <div class="col-sm-5 col-xs-12">
            <div class="text-center">
              <h1 class="text-danger font-weight-bold text-capitalize ps-0 mt-8 mb-4">{{ YEAR }}年報名時間已截止 </h1>
              
              <div class="col-12">
                <h5 class="alert alert-warning alert-dismissible fade show">
                  已報名者，可多多利用以下功能查詢報考資訊
                  <div class="mb-1 mt-4">
                    <a href="/#/Examinee/OrderTrack" 
                      class="btn btn-info mb-2" 

                      >
                        訂單查詢
                    </a>
                    <a href="/#/Examinee/DataManage" 
                      class="btn btn-primary mb-2" 
                      >
                      考生查詢
                    </a>
                  </div>
                </h5>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "oEnd",
  data() {
    return {
      YEAR: new Date().getFullYear(),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      //alert(new Date().getFullYear());
    },
  },
};
</script>

