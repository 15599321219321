<script></script>
<template>
  <div class="main-wrapper course-single">
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">退費辦法</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">首頁</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                考試須知
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!--第二顆按鈕模板 tabs: [{ id: 2, name: '退費辦法' }, ] -->
    <section class="pb-10">
      <div class="container">
        <div class="row pt-8">
          <div class="section-title justify-content-center mb-3">
            <span class="shape shape-left bg-info"></span>
            <h2 class="text-danger">退費方式</h2>
            <span class="shape shape-right bg-info"></span>
          </div>
          <div class="col-sm-12 col-xs-12">
            <div class="mb-4">
              <ol class="mb-4">
                <h5 class="font-size-20">
                  一、考生請於詳閱退費辦法後，點選以下【退費】按鈕，登入會員後申請退費。
                </h5>
                <h5 class="font-size-20">考試退費客服專線 : 02-8665-1745。</h5>
              </ol>
              <ol class="mb-4">
                <h5 class="font-size-20">
                  二、如報名時使用信用卡付款，退費款項將直接退還至您的信用卡帳戶；如報名時使用ATM付款，請於申請退費時提供欲退款之本人帳戶，退費款項將退還至您所提供的帳戶。
                </h5>
                <!-- <ul>
                  <ul class="mb-4 font-size-20">
                    <li>競賽退費客服專線 : 02-8665-1745</li>
                    <li>線上課程退費客服專線 : 02-8665-1234</li>
                  </ul>
                </ul> -->
              </ol>
              <ol class="mb-4">
                <h5 class="font-size-20">
                  三、退費處理時間約為三十個工作天左右；資料填寫有誤或不全者，將影響退費時間。
                </h5>
              </ol>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="section-title justify-content-center mb-3 pt-8">
            <!-- <span class="shape shape-left bg-info"></span> -->
            <h2 class="text-danger">報名費退費辦法</h2>
            <!-- <span class="shape shape-right bg-info"></span> -->
          </div>
          <div class="col-sm-12 col-xs-12">
            <div class="mb-4">
              <ol class="mb-4">
                <h5 class="font-size-20">
                  本考試之退費基準如下，報名者申請退費後，由主辦單位視各考試報名階段，每位退費考生扣除行政作業費後將退款至所提供之本人帳戶：
                </h5>

                <h5 class="font-size-20">一、於考試報名第一階段申請退費者：</h5>
                <ul>
                  <ul>
                    <li class="mb-4 font-size-20">
                      自個人報名繳費成功起至12/13考試報名截止日止，欲申請退賽及退還報名費者，主辦單位將於扣除「行政作業費NT$100」後，將剩餘費用進行退費。
                    </li>
                  </ul>
                </ul>

                <h5 class="font-size-20">二、於考試報名第二階段申請退費者：</h5>
                <ul>
                  <ul class="mb-4 font-size-20">
                    <li class="font-size-20">
                      自12/16日起至12/31止，欲申請退賽及退還報名費者，退費將於扣除「行政作業費NT$200」後，將剩餘費用進行退費。
                    </li>
                  </ul>
                </ul>
                <h5 class="font-size-20">三、於考試報名第三階段申請退費者：</h5>
                <ul>
                  <ul class="mb-4 font-size-20">
                    <li class="font-size-20">
                      自114/1/1起至1/16止，因已進入考試物資準備作業，退費將扣除「報名費50%」後，將剩餘費用進行退費。
                    </li>
                  </ul>
                </ul>

                <h5 class="font-size-20">
                  四、申請退費截止日為1/16，自1/17起申請退費者，恕不受理。
                </h5>

                <p class="text-danger font-size-20">
                  ※
                  如因報名者發生重大傷病或其直系親屬因故傷亡時，應檢附書面證明資料申請退費，主辦單位將進行退費。
                  <br />
                  ※
                  如因地震、颱風、傳染病、罷工、遊行等重大事件因素造成考試無法如期舉行，主辦單位得另行公布相關處理辦法。
                </p>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FeeRefundPolicy",
};
</script>
