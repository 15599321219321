import "babel-polyfill"
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import foundation from 'foundation-sites'
import mixin from './components/Mixin.vue'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { BModal, VBModal } from 'bootstrap-vue'

import './assets/css/jquery.easy_slides.css'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import 'vue-datetime/dist/vue-datetime.css'
import VueCarousel from 'vue-carousel';
import VueApexCharts from 'vue-apexcharts'
import "vue-multiselect/dist/vue-multiselect.min.css";
import VueSocialSharing from 'vue-social-sharing'
import { Datetime } from 'vue-datetime';

import VueFbCustomerChat from 'vue-fb-customer-chat';
import VueCookies from 'vue-cookies'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)
Vue.use(VueCarousel);
Vue.use(VueSocialSharing)
Vue.use(VueCookies)
Vue.component('b-modal', BModal)
Vue.directive('b-modal', VBModal)
Vue.mixin(mixin)
Vue.use(VueAxios, axios)
Vue.use(VueApexCharts)

Vue.prototype.$axios = axios;
Vue.use(foundation);
Vue.component('datetime', Datetime);


Vue.use(VueGtm, {
  id: 'GTM-NHM5R2VL',
  enabled: true,
  debug: true,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false // 是否在 nextTick 上追踪
  // ignoredViews: ['homepage']
});

Vue.use(VueFbCustomerChat, {
  page_id: "106546594322962", // 輸入 Facebook Page ID,
  theme_color: '#6699cc', // 設定messenger顏色
  locale: 'zh_TW', // 設定語言
  logged_out_greeting:"嗨~有問題可以私訊我喔！", //登入狀態歡迎詞
  logged_in_greeting:"您好，很高興為您服務" //登出(未登入)狀態歡迎詞
})

axios.defaults.baseURL = "/api"
Vue.config.productionTip = false

console.log(".env.VUE_APP_TARGET_URL",process.env.VUE_APP_TARGET_URL)
console.log(".env.VUE_APP_RETURN_URL",process.env.VUE_APP_RETURN_URL)
console.log(".env.process.env.NODE_ENV",process.env.NODE_ENV)
const updateMetaTags = (metaTags) => {
  metaTags.forEach(tag => {
    const element = document.querySelector(`meta[${tag.name ? 'name' : 'property'}="${tag.name || tag.property}"]`);

    if (element) {
      element.setAttribute('content', tag.content);
    } else {
      const metaElement = document.createElement('meta');
      if (tag.name) {
        metaElement.setAttribute('name', tag.name);
      }
      if (tag.property) {
        metaElement.setAttribute('property', tag.property);
      }
      metaElement.setAttribute('content', tag.content);
      document.head.appendChild(metaElement);
    }
  });
};

router.beforeEach((to, from, next) => {
  // Update document title
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Update meta tags
  if (to.meta.metaTags) {
    updateMetaTags(to.meta.metaTags);
  }

  next();
});



router.afterEach(() => {
  document.documentElement.scrollTop = 0;
})



new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
