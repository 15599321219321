<script>
export default {
  name: "Mixin",
  data() {
    return {
      userRequest: this.$axios.create({ baseURL: "/api" }), // localhost路徑
      //userRequest: this.$axios.create({ baseURL: "/API" }), // 測試機路徑
      apiToken: (data) => this.userRequest.post("KC/Token", data),
      TokenID: null,
      page: "",
      permission: {
        isView: true,
        isInsert: false,
        isDelete: false,
        isUpdate: false,
      },
      fontSizeDefault: 1.2,
      fontSize: 'font-size:1.2rem !important;',
      apiContent: (data) =>
        this.userRequest.post("KC/Content", data),
      apiPROJECT: (data) =>
        this.userRequest.post("KC/PROJECT", data),
    };
  },
  watch: {
    "fontSizeDefault"() {
      this.fontSize = 'font-size:' + this.fontSizeDefault + 'rem !important;';
    },
  },
  mounted() {
  },
  methods: {
    updateHitRate(value) {
      let json = {
        TYPE: "UPDATE_HITRATE",
        content_SELECT: {
          contentId: value,
        },
      }
      //console.log(json)
      this.FunctionToken(this.UpdateContentHitRate, json);
    },
    UpdateContentHitRate(data) {
      this.apiContent(data)
        .then(() => {
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPermission(value) {
      let json = {
        TYPE: "CHECK_PERMISSION",
        PROJECT_SELECT: {
          PJ_ID: value,
        },
      }
      this.FunctionToken(this.FunctionGetPermission, json);
    },
    FunctionGetPermission(data) {
      this.apiPROJECT(data)
        .then((res) => {
          let json = JSON.parse(res.data);
          if (json.Status) {
            this.permission = JSON.parse(json.Data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    FunctionToken: function (EXfunction, data_in) {
      this.apiToken({})
        .then((res) => {
          data_in.TokenID = this.TokenID;
          data_in.Token = res.data;
          // data_in.Page = this.page;
          EXfunction(data_in);
        })
        .catch((error) => {
          alert("發生錯誤!" + error);
        });
    },
    FunctionTokenAsync(data_in) {
      return new Promise((resolve, reject) => {
        this.apiToken({})
          .then((res) => {
            // console.log([EXfunction, data_in])
            data_in.TokenID = this.TokenID;
            data_in.Token = res.data;
            resolve(data_in);
            //EXfunction(data_in);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 千分位逗點
    toThousands(number) {
      var num = (number || 0).toString(),
        result = "";
      while (num.length > 3) {
        result = "," + num.slice(-3) + result;
        num = num.slice(0, num.length - 3);
      }
      if (num) {
        result = num + result;
      }
      return result;
    },
    yearConvert(date) {
      // 轉民國年
      if (date) {
        // console.log(date)
        let newDate = date.split("T")[0];
        newDate = newDate.split("-");
        let newYear = newDate[0] - 1911;
        newDate = newYear + "/" + newDate[1] + "/" + newDate[2];
        return newDate;
      }
      return "-";
    },
    dateFormat(date) {
      // 西元年日期格式
      if (date) {
        date = date.split("T")[0];
        date = date.replaceAll("-", "/");
        return date;
      } else {
        return '';
      }
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key] === value);
    },

    //===============================================================================
    Str_Replace(str, if1, if2) {
      return (str + "").replace(new RegExp(if1, "g"), if2);
    },
    StrCap(str1, n1, n2) {
      if (n2 < 0) {
        return str1.substr(n1, str1.length + n2);
      } else {
        return str1.substr(n1, n2);
      }
    },
    DeepCopy(data, if1 = 0) {
      if (if1 == 0) {
        return JSON.parse(JSON.stringify(data));
      }
      /*
      else if(if1==1){
        return $.extend(true, {}, data);;
      }
      */
    },
    IsNull(data, err = '') {
      if (data == "" || data == null || data == undefined) {
        return err;
      } else {
        return data;
      }
    },
    isFloat(n) {
      return n === n && n !== (n | 0);
    },
    _localData(key, val = undefined) {
      if (val == undefined) {
        return localStorage.getItem(key);
      } else if (val == "") {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, val);
      }
    },
    _sessionData(key, val = undefined) {
      if (val == undefined) {
        return sessionStorage.getItem(key);
      } else if (val == "") {
        sessionStorage.removeItem(key);
      } else {
        sessionStorage.setItem(key, val);
      }
    },
    _go(url) {
      if (isNaN(url)) {
        this.$router.push(url);
      } else {
        this.$router.go(url);
      }
    },
    _go1(url, if1 = 0, name = "") {
      if (if1 == 0) {
        window.location.href = url;
      } else if (if1 == 1) {
        window.open(url);
      } else if (if1 == 2) {
        window.open(url, name, "height=768,width=1024");
      } else if (if1 == 3) {
        if (this.windowOpen != "") {
          this.windowOpen.opener = null;
          this.windowOpen.close();
        }
        this.windowOpen = window.open(url, "test", "height=768,width=1024");
      }
    },
    _go2(json, type = "params") {
      /*
      {
        name:'test1',
        path:'/test1',
        data:{},
        key:'www',
      }
      */
      if (type == "params") {
        if (this.IsNull(json.key) != "") {
          return this.$route.params[json.key];
        } else {
          this.$router.push({
            name: json.name,
            params: json.data,
          });
        }
      } else if (type == "query") {
        if (this.IsNull(json.key) != "") {
          return this.$route.query[json.key];
        } else {
          this.$router.push({
            path: json.path,
            query: json.data,
          });
        }
      }
    },
    Str_Blank(str) {
      return this.Str_Replace(
        this.Str_Replace(this.Str_Replace(str, " ", ""), "\t", ""),
        "\r",
        ""
      );
    },
    keyupNum(data, key) {
      //限定只輸入數字
      if (isNaN(Number(data[key]))) {
        data[key] = "";
      }
    },
    numAdd0(num, len) {
      let n1 = len - (num + "").length;
      if (n1 <= 0) {
        return num;
      }
      let str = "";
      for (let i = 0; i < n1; i++) {
        str += "0";
      }
      return str + (num + "");
    },
    decryptcontent(data) {
      if (data != null || data != undefined) {
        let test = this.CryptoJS.AES.decrypt(
          data,
          "Secret Passphrase"
        ).toString(this.CryptoJS.enc.Utf8);
        return test;
      } else {
        return "";
      }
    },
  },
};
</script>