function isLoggedIn() {
    const isLogin = sessionStorage.getItem("isLogin") === "true";
    return isLogin;
  }


function ssologin() {


    var targetUrl =process.env.VUE_APP_TARGET_URL;
    //var targetUrl = "https://membercenter.knsh.com.tw/membercenter/MC_LOGIN";
      var data = {
        syscode: "4",
         returnurl: process.env.VUE_APP_RETURN_URL,
        //returnurl: "https://www.kc-test.com.tw/#/Member/Login/",
      };

      fetch(targetUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          mode: "no-cors",
          credentials: "include",
        },
        body: JSON.stringify(data),
      }).then((response) => {
        if (response.redirected) {
          window.location.href = response.url;
          return;
        }
        return response.text();
      });
  }

  function checkLogin() {
    if (!isLoggedIn()) {
        sessionStorage.setItem("redirectUrl", window.location.href);
      ssologin();
    }
  }

  function redirectToOriginalUrl() {
    const redirectUrl = sessionStorage.getItem('redirectUrl');
    const name =  sessionStorage.getItem("LoginName");
    console.log("🚀 ~ redirectToOriginalUrl ~ name:", name)

    if(name == null|| name == ""){
      //
      window.location.href = "/#/Member/Modify/";
    }else{
      if (redirectUrl) {
        window.location.href = redirectUrl;
        sessionStorage.removeItem('redirectUrl');
      }else{
          window.location.href = "/";
      }
    }
    
  }
  

  export { checkLogin, ssologin,redirectToOriginalUrl };