<template>
  <div>
    <LoginModal :loginModal="loginModal" @init="init()"></LoginModal>
    <!-- Top Color Bar -->
    <div class="color-bars">
      <div class="container-fluid">
        <div class="row">
          <div class="col color-bar bg-warning d-none d-md-block"></div>
          <div class="col color-bar bg-success d-none d-md-block"></div>
          <div class="col color-bar bg-danger d-none d-md-block"></div>
          <div class="col color-bar bg-info d-none d-md-block"></div>
          <div class="col color-bar bg-purple d-none d-md-block"></div>
          <div class="col color-bar bg-pink d-none d-md-block"></div>
          <div class="col color-bar bg-warning"></div>
          <div class="col color-bar bg-success"></div>
          <div class="col color-bar bg-danger"></div>
          <div class="col color-bar bg-info"></div>
          <div class="col color-bar bg-purple"></div>
          <div class="col color-bar bg-pink"></div>
        </div>
      </div>
    </div>

    <div class="bg-stone top-bar">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 d-none d-lg-block">
            <ul
              class="list-inline d-flex justify-content-xl-start align-items-center h-100 mb-0"
            >
              <!--li>
                <span class="bg-success icon-header me-xl-2">
                  <i class="fas fa-phone-alt" aria-hidden="true"></i>
                </span>
                <a href="tel:+886286651745" class="me-lg-4 me-xl-6 text-white opacity-80">
                  客服專線 (02) 8665-1745
                </a>
              </li-->
              <li class="text-white">
                <span class="bg-primary icon-header me-xl-2"
                  ><i aria-hidden="true" class="fab fa-facebook-f"></i
                ></span>
                <span class="opacity-80"
                  ><a
                    class="me-lg-4 me-xl-6 text-white opacity-80"
                    href="https://www.facebook.com/%E5%BA%B7%E8%BB%92%E5%8D%93%E8%B6%8A%E7%9B%83%E6%95%B8%E5%AD%B8%E9%96%B1%E8%AE%80%E7%AB%B6%E8%B3%BD-106546594322962"
                    >卓越盃粉絲團</a
                  ></span
                >
              </li>
            </ul>
          </div>

          <div class="col-lg-5">
            <ul
              class="list-inline d-flex mb-0 justify-content-xl-end justify-content-center align-items-center me-xl-2"
            >
              <li class="text-white me-md-3 me-lg-2 me-xl-5">
                <div v-if="isLogin">
                  <span class="bg-purple icon-header me-1 me-md-2 me-lg-1 me-xl-2">
                    <i
                      class="fas fa-unlock-alt text-white font-size-13"
                      aria-hidden="true"
                    />
                  </span>
                  <a
                    href="/#/Member/OrderTrack"
                    class="text-white font-weight-medium opacity-80"
                    >{{ loginName }}
                  </a>
                  (
                  <a
                    href="/#/Member/Data"
                    class="text-white font-weight-medium opacity-80"
                    >會員維護
                  </a>

                  )
                  <span class="text-white opacity-80">/ </span>
                  <a
                    href="javascript:void(0)"
                    class="text-white font-weight-medium opacity-80"
                    @click="memberLogout"
                    >登出
                  </a>
                </div>
                <div v-if="!isLogin">
                  <span class="bg-purple icon-header me-1 me-md-2 me-lg-1 me-xl-2">
                    <i
                      class="fas fa-unlock-alt text-white font-size-13"
                      aria-hidden="true"
                    />
                  </span>
                  <a
                    href="#"
                    @click="doLogin"
                    class="text-white font-weight-medium opacity-80"
                    >登入 / 註冊
                  </a>
                  <!-- <router-link
                    class="text-white font-weight-medium opacity-80"
                    to="/Examinee/Register"
                    >註冊
                  </router-link> -->
                  <!-- <a
                    href="javascript:void(0)"
                    class="text-white font-weight-medium opacity-80"
                    v-b-modal.modal-login
                    >登入
                  </a>
                  <span class="text-white opacity-80">/ </span>

                  <router-link
                    class="text-white font-weight-medium opacity-80"
                    to="/Examinee/Register"
                    >註冊
                  </router-link> -->
                  <!-- <span class="text-white opacity-80">/ </span> -->
                  <!-- <a
                    href="#"
                    @click="ssoLogin"
                    class="text-white font-weight-medium opacity-80"
                    >SSO 登入
                  </a> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginModal from "./Login";
import { ssologin } from "@/utils/auth";
export default {
  components: {
    LoginModal,
  },
  data() {
    return {
      isLogin: false,
      memberId: null,
      loginName: "",
      loginModal: {
        show: false,
        noCloseOnBackdrop: false,
        noCloseOnEsc: false,
      },
    };
  },
  watch: {
    $route() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.isLogin = sessionStorage.getItem("isLogin") === "true";
      this.memberId = sessionStorage.getItem("MemberId");
      this.loginName = sessionStorage.getItem("LoginName");
    },
    memberLogout() {
      this.isLogin = false;
      this.LoginName = "";

      sessionStorage.setItem("MemberId", null);
      sessionStorage.setItem("LoginName", null);
      sessionStorage.setItem("isLogin", false);

      location.href = "/";
    },
    doLogin() {
      ssologin();
    },
    // ssoLogin() {
    //   var targetUrl = "https://mctest.knsh.com.tw/membercenter/MC_LOGIN";
    //   var data = {
    //     syscode: "4",
    //     returnurl: "https://1f1bea11a442.ngrok.app/#/Member/Login/",
    //   };

    //   fetch(targetUrl, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       mode: "no-cors",
    //       credentials: "include",
    //     },
    //     body: JSON.stringify(data),
    //   }).then((response) => {
    //     if (response.redirected) {
    //       window.location.href = response.url;
    //       return;
    //     }
    //     return response.text();
    //   });
    // },
  },
};
</script>
