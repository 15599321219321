
<template>
  <div class="main-wrapper blog-single-left-sidebar">
    <section
      class="breadcrumb-bg"
      style="background-image: url(assets/img/background/page-title-bg-img.jpg)"
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">尚未開放報名</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">首頁</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                我要報名
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-sm-6 col-xs-12">
            <div class="text-center">
              <img src="/assets/img/examroom/pic1.jpg" class="w-100 rounded" alt="" />
            </div>
          </div>
          <div class="col-sm-5 col-xs-12">
            <div class="text-center">
              <h1 class="text-danger font-weight-bold text-capitalize ps-0 mt-8">{{ YEAR }}年度尚未開放報名 </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "oOpen",
  data() {
    return {
      YEAR: new Date().getFullYear(),
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
  },
};
</script>

